import { Suspense, lazy } from 'react'
import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	isRouteErrorResponse,
	Outlet,
	useRouteError,
} from 'react-router-dom'
// import Layout from './layout'
import Status from '../components/Status'
import Loading from './loading'
// import Home from './Home'
// import BlockchainDevelopment from './BlockchainDevelopment'
const BlockchainDevelopment = lazy(() => import('./BlockchainDevelopment'))
// import ExclusiveNFT from './ExclusiveNFT'
const ExclusiveNFT = lazy(() => import('./ExclusiveNFT'))
const SmartContractAuditing = lazy(() => import('./SmartContractAuditing'))
const AuditProcessDetails = lazy(() => import('./AuditProcessDetails'))
const Blog = lazy(() => import('../blog/Blog'))
const Blogs = lazy(() => import('../blog/Blogs'))
const NFTDevelopment = lazy(() => import('./NFTDevelopment'))
const HyperledgerBlockchainDevelopment = lazy(() => import('./HyperledgerBlockchainDevelopment'))
const Trigon = lazy(() => import('./Trigon'))
const Pledge = lazy(() => import('./Pledge'))
const Media = lazy(() => import('./Media'))
const ContactUs = lazy(() => import('./ContactUs'))
const Survey = lazy(() => import('./Survey'))
const FreeAuditForm = lazy(() => import('./FreeAuditForm'))
const PrivacyStatement = lazy(() => import('./PrivacyStatement'))
const BlockchainSelectionGuide = lazy(() => import('./BlockchainSelectionGuide'))
const Layout = lazy(() => import('./layout'))
const Home = lazy(() => import('./Home'))
// const Home = lazyRetry('./Home.js')

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			exact
			element={
				<Layout>
					<Suspense fallback={<Loading />}>
						<Outlet />
					</Suspense>
				</Layout>
			}
			errorElement={
				<Layout>
					<ErrorBoundary />
				</Layout>
			}
		>
			<Route exact path='/loading' element={<Loading />} />
			<Route exact path='/' element={<Home />} />
			<Route exact path='/services/blockchain-development' element={<BlockchainDevelopment />} />
			<Route exact path='/ExclusiveNFT' element={<ExclusiveNFT />} />
			<Route exact path='/services/smart-contract-auditing' element={<SmartContractAuditing />} />
			<Route exact path='/services/smart-contract-auditing/details' element={<AuditProcessDetails />} />
			<Route exact path='/blog/:slug' element={<Blog />} />
			<Route exact path='/blogs' element={<Blogs />} />

			<Route exact path='/services/nft' element={<NFTDevelopment />} />
			<Route exact path='/services/hyperledger' element={<HyperledgerBlockchainDevelopment />} />
			<Route exact path='/services/trigon' element={<Trigon />} />
			<Route exact path='/services/pledge' element={<Pledge />} />
			<Route exact path='/media' element={<Media />} />
			<Route exact path='/contact-us' element={<ContactUs />} />
			<Route exact path='/survey' element={<Survey />} />
			<Route exact path='/free-audit' element={<FreeAuditForm />} />
			<Route exact path='/privacy-statement' element={<PrivacyStatement />} />
			<Route exact path='/blockchain-selection-guide' element={<BlockchainSelectionGuide />} />
		</Route>,
	),
)
export default router
function ErrorBoundary() {
	const error = useRouteError()
	if (isRouteErrorResponse(error)) {
		return <Status type='error' error={error} />
	}
	return <Status type='oops' />
}
