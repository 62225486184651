import { ProgressSpinner } from 'primereact/progressspinner'

function Loading() {
	return (
		<center className='h-30rem grid'>
			<ProgressSpinner className='w-8rem h-8rem' style={{ placeSelf: 'center' }} />
		</center>
	)
}

export default Loading
