import { Link } from 'react-router-dom'

//Primereact components
import { Button } from 'primereact/button'

import loadingData from './assets/loading_data.webp'
import noResults from './assets/no_results.webp'
import notFound from './assets/not_found.webp'
import oops from './assets/oops.webp'
import web3tech from './assets/web3tech-logo.webp'

const ImageContainer = ({ image, label, buttonEnabled, refresh }) => {
	return (
		<div className='h-full h-full py-2'>
			<div className='flex align-items-center justify-content-center'>
				<img
					loading='eager'
					className='w-15rem md:w-18rem md:w-22rem lg:w-25rem h-15rem md:h-18rem md:h-22rem lg:h-25rem '
					style={{ objectFit: 'contain' }}
					src={image}
					alt='error description'
				/>
			</div>
			{label && <div className='w-full text-center text-2xl lg:text-4xl mt-0 lg:mt-3 text-600'>{label}</div>}
			{refresh && (
				<div className='flex align-items-center justify-content-center'>
					<div className='flex w-3 justify-content-between'>
						<Button
							label='Home'
							icon='pi pi-home'
							className='p-button-md mt-2'
							onClick={() => (window.location.href = '/')}
						/>
						<Button
							label='Refresh'
							icon='pi pi-refresh'
							className='p-button-md mt-2'
							onClick={() => window.location.reload()}
						/>
					</div>
				</div>
			)}

			{buttonEnabled && (
				<div className='flex align-items-center justify-content-center'>
					<Link to='/'>
						<Button label='Home' icon='pi pi-home' className='p-button-md mt-2' />
					</Link>
				</div>
			)}
		</div>
	)
}

const ErrorMessage = ({ image, error }) => (
	<div className='h-full h-full py-2'>
		<div className='flex align-items-center justify-content-center'>
			<img
				loading='eager'
				className='w-15rem md:w-18rem md:w-22rem lg:w-25rem h-15rem md:h-18rem md:h-22rem lg:h-25rem '
				style={{ objectFit: 'contain' }}
				src={image}
				alt='error description'
			/>
		</div>
		{error.status && (
			<h2 className='w-full text-center text-2xl lg:text-4xl mt-0 lg:mt-3 text-600'>{error?.status ?? 'status'}</h2>
		)}
		{error.statusText && (
			<p className='flex align-items-center justify-content-center'>{error?.statusText ?? 'statusText'}</p>
		)}
		{error.data?.message && (
			<p className='flex align-items-center justify-content-center'>{error?.data?.message ?? 'message'}</p>
		)}
	</div>
)

/**
 *
 * @param {'not-found'|'loading'|'no-data'|'background'|'oops'|'error'} param0
 * @returns
 */
const Status = ({ type, error }) => {
	switch (String(type)) {
		case 'not-found':
			return <ImageContainer image={notFound} label='Not Found' buttonEnabled={true} />
		case 'loading':
			return <ImageContainer image={loadingData} label='Loading Data' />
		case 'no-data':
			return <ImageContainer image={noResults} label='No Data Found' />
		case 'background':
			return <ImageContainer image={web3tech} label='Loading' />
		case 'oops':
			return <ImageContainer image={oops} label='Something went wrong' refresh={true} />
		case 'error':
			return <ErrorMessage image={oops} error={error} />
		default:
			return <ImageContainer image={notFound} label='Something went wrong' refresh={true} />
	}
}

export default Status
